import type { ApolloClient } from 'apollo-client';

import type { OptionData, Promisable } from '@atlaskit/user-picker';

import { ExternalUserLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalUserLozengeProps';
import { ExternalGroupLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalGroupLozengeProps';
import { perfMarkStart, perfMarkEnd } from '@confluence/performance';

import type { SitePermissionTypeFilter } from './__types__/UserAndGroupSearchQuery';
import { SitePermissionType } from './__types__/UserAndGroupSearchQuery';
import type { UserGroupItemProps } from './UserAndGroupSearchPicker';
import { UserAndGroupSearchQuery } from './UserAndGroupSearchQuery.graphql';

type userGroupSearchArgs = {
	searchTerm: string;
	client: ApolloClient<any>;
	includeGroup: boolean;
	setError: (error: Error) => void;
	sitePermissionTypeFilter: SitePermissionTypeFilter;
	filterResults?: (value: UserGroupItemProps) => boolean;
	withUsers?: boolean;
};

export const userGroupSearchPromise = ({
	searchTerm,
	client,
	includeGroup,
	setError,
	sitePermissionTypeFilter,
	filterResults,
	withUsers,
}: userGroupSearchArgs): Promisable<OptionData[]> => {
	const subject = 'userAndGroupSearchQuery';
	perfMarkStart({
		subject,
	});
	return client
		.query({
			query: UserAndGroupSearchQuery,
			variables: {
				withGroups: includeGroup,
				searchTerm,
				sitePermissionTypeFilter,
				withUsers,
			},
		})
		.then(({ data }) => {
			const { users = [], groups = [] } = data.userGroupSearch;
			const userList: UserGroupItemProps[] = users.map((item) => ({
				id: `user-${item.accountId}`,
				name: item.displayName,
				extra: item,
				type: 'user',
				avatarUrl: item.profilePicture ? item.profilePicture.path : '',
				lozenge:
					item.permissionType === SitePermissionType.EXTERNAL ? ExternalUserLozengeProps : null,
			}));
			const groupList: UserGroupItemProps[] = groups.map((item) => ({
				id: `group-${item.id}`,
				name: item.name,
				type: 'group',
				extra: item,
				lozenge:
					item.permissionType === SitePermissionType.EXTERNAL ? ExternalGroupLozengeProps : null,
			}));
			const usersAndGroups = [...userList, ...groupList];
			perfMarkEnd({
				subject,
			});
			return filterResults ? usersAndGroups.filter(filterResults) : usersAndGroups;
		})
		.catch((error) => {
			setError(error);
			return [];
		});
};
