import gql from 'graphql-tag';

import { ExternalCollaboratorEntitlementFragment } from '@confluence/external-collab-ui/entry-points/ExternalCollaboratorEntitlementFragment';

export const UserAndGroupSearchPickerQuery = gql`
	query UserAndGroupSearchPickerQuery {
		...ExternalCollaboratorEntitlementFragment
	}

	${ExternalCollaboratorEntitlementFragment}
`;
