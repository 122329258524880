import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AkLozenge from '@atlaskit/lozenge';
import AkTooltip from '@atlaskit/tooltip/Tooltip';

import type { LozengeProps } from './types';

const i18n = defineMessages({
	externalGroupLozengeText: {
		id: 'external-collab-ui.group-guest-lozenge',
		defaultMessage: 'Guest',
		description: 'Text for a lozenge showing that a group is for guests in Confluence',
	},
	externalGroupTooltip: {
		id: 'external-collab-ui.group-guest-lozenge.tooltip',
		defaultMessage:
			'Guest groups can only access certain spaces and have limited access to user info.',
		description: 'Tooltip text for lozenge showing that a group is for guests in Confluence',
	},
});

export const ExternalGroupLozengeProps: LozengeProps = {
	text: (
		<AkTooltip content={<FormattedMessage {...i18n.externalGroupTooltip} />}>
			<FormattedMessage {...i18n.externalGroupLozengeText} />
		</AkTooltip>
	),
	appearance: 'new',
	isBold: false,
};

export const ExternalGroupLozenge: FC = () => {
	const { text, appearance, isBold } = ExternalGroupLozengeProps;
	return (
		<AkLozenge appearance={appearance} isBold={isBold}>
			{text}
		</AkLozenge>
	);
};
