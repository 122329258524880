import gql from 'graphql-tag';

export const UserAndGroupSearchQuery = gql`
	query UserAndGroupSearchQuery(
		$searchTerm: String!
		$maxResults: Int = 50
		$withGroups: Boolean = true
		$withUsers: Boolean = true
		$sitePermissionTypeFilter: SitePermissionTypeFilter = NONE
	) {
		userGroupSearch(
			query: $searchTerm
			maxResults: $maxResults
			sitePermissionTypeFilter: $sitePermissionTypeFilter
		) {
			users @include(if: $withUsers) {
				type
				displayName
				permissionType
				profilePicture {
					path
				}
				... on User {
					accountId
				}
				... on KnownUser {
					accountId
				}
			}
			groups @include(if: $withGroups) {
				id
				name
				permissionType
			}
		}
	}
`;
