/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

export enum SitePermissionTypeFilter {
  ALL = "ALL",
  EXTERNALCOLLABORATOR = "EXTERNALCOLLABORATOR",
  NONE = "NONE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAndGroupSearchQuery
// ====================================================

export interface UserAndGroupSearchQuery_userGroupSearch_users_Anonymous_profilePicture {
  path: string;
}

export interface UserAndGroupSearchQuery_userGroupSearch_users_Anonymous {
  type: string | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
  profilePicture: UserAndGroupSearchQuery_userGroupSearch_users_Anonymous_profilePicture | null;
}

export interface UserAndGroupSearchQuery_userGroupSearch_users_User_profilePicture {
  path: string;
}

export interface UserAndGroupSearchQuery_userGroupSearch_users_User {
  type: string | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
  profilePicture: UserAndGroupSearchQuery_userGroupSearch_users_User_profilePicture | null;
  accountId: string | null;
}

export interface UserAndGroupSearchQuery_userGroupSearch_users_KnownUser_profilePicture {
  path: string;
}

export interface UserAndGroupSearchQuery_userGroupSearch_users_KnownUser {
  type: string | null;
  displayName: string | null;
  permissionType: SitePermissionType | null;
  profilePicture: UserAndGroupSearchQuery_userGroupSearch_users_KnownUser_profilePicture | null;
  accountId: string | null;
}

export type UserAndGroupSearchQuery_userGroupSearch_users = UserAndGroupSearchQuery_userGroupSearch_users_Anonymous | UserAndGroupSearchQuery_userGroupSearch_users_User | UserAndGroupSearchQuery_userGroupSearch_users_KnownUser;

export interface UserAndGroupSearchQuery_userGroupSearch_groups {
  id: string | null;
  name: string | null;
  permissionType: SitePermissionType | null;
}

export interface UserAndGroupSearchQuery_userGroupSearch {
  users: (UserAndGroupSearchQuery_userGroupSearch_users | null)[];
  groups: (UserAndGroupSearchQuery_userGroupSearch_groups | null)[];
}

export interface UserAndGroupSearchQuery {
  userGroupSearch: UserAndGroupSearchQuery_userGroupSearch | null;
}

export interface UserAndGroupSearchQueryVariables {
  searchTerm: string;
  maxResults?: number | null;
  withGroups?: boolean | null;
  withUsers?: boolean | null;
  sitePermissionTypeFilter?: SitePermissionTypeFilter | null;
}
